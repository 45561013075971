import { Controller } from '@hotwired/stimulus';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.css';

export default class extends Controller {
    static targets = ['element'];

    connect () {
        this.$glightbox = GLightbox({ elements: this.elementTargets });
    }

    disconnect () {
        if (this.$glightbox) {
            this.$glightbox.destroy();
        }
    }

    open (event) {
        if (event.params.position) {
            this.$glightbox.openAt(event.params.position);
        } else {
            this.$glightbox.open();
        }
    }
}
