import { Controller } from '@hotwired/stimulus';
import { tns as TinySlider } from 'tiny-slider/src/tiny-slider.module';
import 'tiny-slider/src/tiny-slider.scss';

export default class extends Controller {
    static targets = ['container', 'navigation'];

    connect () {
        if (!this.hasContainerTarget) {
            return;
        }

        this.slider = TinySlider({
            container: this.containerTarget,
            disable: true,
            mode: 'gallery',
            controls: false,
            nav: true,
            navContainer: this.navigationTarget,
            responsive: {
                768: {
                    disable: false,
                }
            },
        });
    }

    disconnect () {
        if (this.slider) {
            this.slider.destroy();
        }
    }
}
