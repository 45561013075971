import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['filters', 'categories', 'search', 'frame'];
    static classes = ['categoriesActive'];

    static values = {
        categories: Array,
        categoriesLabel: String,
    };

    connect () {
        const urlParams = new URLSearchParams(window.location.search);

        this.compileCategories(urlParams);

        window.addEventListener('click', () => {
            this.element.dispatchEvent(new CustomEvent('clientsfilter:close'));
        }, { passive: true });

        let searchTimer;
        this.searchTarget.addEventListener('keyup', () => {
            clearTimeout(searchTimer);
            searchTimer = setTimeout(() => {
                this.frameTarget.src = this.appendQuery('keywords', this.searchTarget.value);
            }, 100);
        });

        this.searchTarget.value = urlParams.get('keywords') || '';

        document.documentElement.addEventListener('turbo:frame-load', (event) => {
            if (event.target === this.frameTarget && new URL(this.frameTarget.src).search === '') {
                this.searchTarget.value = '';
                this.element.dispatchEvent(new CustomEvent('clientsfilter:reset'));
            }
        });

        this.filtersTarget.hidden = false;
    }

    compileCategories (urlParams) {
        if (!this.categoriesTarget) {
            return;
        }

        const root = document.createElement('ul');
        const current = this.createButton(this.categoriesLabelValue, root, 'current', this.categoriesActiveClass);

        this.categoriesTarget.append(current, root);

        this.element.addEventListener('clientsfilter:close', () => {
            root.classList.remove('active');
            this.filtersTarget.classList.remove(this.categoriesActiveClass)
        });

        this.element.addEventListener('clientsfilter:reset', () => {
            current.innerText = this.categoriesLabelValue;
        });

        const select = (label) => {
            current.innerText = label;
            this.element.dispatchEvent(new CustomEvent('clientsfilter:close'));
        }

        root.append(this.createLink(null, this.categoriesLabelValue, 'category', select));

        Array.from(this.categoriesValue).sort((a, b) => a.name.localeCompare(b.name)).forEach((c) => {
            root.append(this.createLink(c.id, c.name, 'category', select));

            if (Number(urlParams.get('category')) === c.id) {
                select(c.name);
            }
        });
    }

    createButton (label, activeTarget, cssClass = '', filterClass) {
        const button = document.createElement('button');
        button.innerText = label;
        button.className = cssClass;

        button.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            const active = activeTarget.classList.contains('active');
            this.element.dispatchEvent(new CustomEvent('clientsfilter:close'));

            if (!active) {
                this.element.dispatchEvent(new CustomEvent('clientsfilter:close'));
                activeTarget.classList.add('active');
                this.filtersTarget.classList.add(filterClass);
            }
        }, { capture: true, passive: false });

        return button;
    }

    createLink (id, label, type, action) {
        const href = this.appendQuery(type, id);

        const node = this.createNode('a', id, label, (link) => {
            link.classList.add('active');
            if (action) action(label);
        }, '', href);

        document.documentElement.addEventListener('turbo:frame-load', (event) => {
            if (event.target === this.frameTarget) {
                node.querySelector('a').href = this.appendQuery(type, id);
            }
        });

        return node;
    }

    createNode (tagName, id, name, action, cssClass = '', href) {
        const li = document.createElement('li');
        const item = document.createElement(tagName);
        item.className = cssClass;
        item.append(name);

        if (tagName === 'button') {
            item.type = 'button';

            item.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                action(item);
            }, { capture: true, passive: false });
        } else if (tagName === 'a') {
            item.href = href;

            item.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                this.frameTarget.src = item.href;
                window.history.pushState(null, '', item.href);
                action(item);
            }, { capture: true, passive: false })
        }

        li.append(item);

        return li;
    }

    appendQuery(key, value) {
        const href = new URL(this.frameTarget.src || window.location.href);
        const params = href.searchParams;

        if (value) {
            params.set(key, value);
        } else {
            params.delete(key);
        }

        params.delete('page');

        href.search = params.toString();

        return href;
    }
}
