import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['filters', 'clients', 'categories', 'search', 'frame'];
    static classes = ['clientsActive', 'categoriesActive'];

    static values = {
        clients: Array,
        categories: Array,
        clientsLabel: String,
        categoriesLabel: String,
    };

    connect () {
        const urlParams = new URLSearchParams(window.location.search);

        this.compileClients(urlParams);
        this.compileCategories(urlParams);

        window.addEventListener('click', () => {
            this.element.dispatchEvent(new CustomEvent('newsfilter:close'));
        }, { passive: true });

        let searchTimer;
        this.searchTarget.addEventListener('keyup', () => {
            clearTimeout(searchTimer);
            searchTimer = setTimeout(() => {
                this.frameTarget.src = this.appendQuery('keywords', this.searchTarget.value);
            }, 100);
        });

        this.searchTarget.value = urlParams.get('keywords') || '';

        document.documentElement.addEventListener('turbo:frame-load', (event) => {
            if (event.target === this.frameTarget && new URL(this.frameTarget.src).search === '') {
                this.searchTarget.value = '';
                this.element.dispatchEvent(new CustomEvent('newsfilter:reset'));
            }
        });

        this.filtersTarget.hidden = false;
    }

    compileClients (urlParams) {
        if (!this.clientsTarget) {
            return;
        }

        const root = document.createElement('ul');
        const current = this.createButton(this.clientsLabelValue, root, 'current', this.clientsActiveClass);
        this.clientsTarget.append(current, root);

        this.element.addEventListener('newsfilter:close', () => {
            root.classList.remove('active');
            root.querySelectorAll('.active').forEach((el) => {
                el.classList.remove('active');
            });
            this.filtersTarget.classList.remove(this.clientsActiveClass)
        }, { passive: true })

        this.element.addEventListener('newsfilter:reset', () => {
            current.innerText = this.clientsLabelValue;
        });

        const select = (label) => {
            current.innerText = label;
            this.element.dispatchEvent(new CustomEvent('newsfilter:close'));
        }

        root.append(this.createLink(null, this.clientsLabelValue, 'client', select));

        const clients = this.clientsValue.sort((a, b) => a.name.localeCompare(b.name));

        let firstLetters = clients.map(c => c.name.substring(0, 1));
        firstLetters = firstLetters.filter((w, i) => i === firstLetters.indexOf(w)).sort((a, b) => a.localeCompare(b));

        const groups = ['ABCDE', 'FGHIJ', 'KLMNO', 'PQRST', 'UVWXYZ'].map(c => c.split(''));

        groups.forEach((letters) => {
            if (!clients.filter(c => letters.includes(c.name.substring(0, 1).toUpperCase()) || (letters.includes('A') && /^\d/.test(c.name))).length) {
                return;
            }

            const group = this.createNode('button', '', `${letters[0]} - ${letters[letters.length - 1]}`, () => this.toggleClientGroup(group));
            const parent = document.createElement('ul');
            group.append(parent);
            root.append(group);

            letters.forEach((letter) => {
                if (!firstLetters.includes(letter) && (letter !== 'A' || !clients.find(c => /^\d/.test(c.name)))) {
                    return;
                }

                const lGroup = this.createNode('strong', '', letter);
                const lParent = document.createElement('ul');
                lGroup.append(lParent);
                parent.append(lGroup);

                clients
                    .filter(c => c.name.substring(0, 1).toUpperCase() === letter || (letter === 'A' && /^\d/.test(c.name)))
                    .forEach((client) => {
                        lParent.append(this.createLink(client.id, client.name, 'client', select));

                        if (Number(urlParams.get('client')) === client.id) {
                            select(client.name);
                        }
                    })
                ;
            })
        });
    }

    compileCategories (urlParams) {
        if (!this.categoriesTarget) {
            return;
        }

        const root = document.createElement('ul');
        const current = this.createButton(this.categoriesLabelValue, root, 'current', this.categoriesActiveClass);

        this.categoriesTarget.append(current, root);

        this.element.addEventListener('newsfilter:close', () => {
            root.classList.remove('active');
            this.filtersTarget.classList.remove(this.categoriesActiveClass)
        });

        this.element.addEventListener('newsfilter:reset', () => {
            current.innerText = this.categoriesLabelValue;
        });

        const select = (label) => {
            current.innerText = label;
            this.element.dispatchEvent(new CustomEvent('newsfilter:close'));
        }

        root.append(this.createLink(null, this.categoriesLabelValue, 'category', select));

        Array.from(this.categoriesValue).sort((a, b) => a.name.localeCompare(b.name)).forEach((c) => {
            root.append(this.createLink(c.id, c.name, 'category', select));

            if (Number(urlParams.get('category')) === c.id) {
                select(c.name);
            }
        });
    }

    createButton (label, activeTarget, cssClass = '', filterClass) {
        const button = document.createElement('button');
        button.innerText = label;
        button.className = cssClass;

        button.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            const active = activeTarget.classList.contains('active');
            this.element.dispatchEvent(new CustomEvent('newsfilter:close'));

            if (!active) {
                this.element.dispatchEvent(new CustomEvent('newsfilter:close'));
                activeTarget.classList.add('active');
                this.filtersTarget.classList.add(filterClass);
            }
        }, { capture: true, passive: false });

        return button;
    }

    createLink (id, label, type, action) {
        const href = this.appendQuery(type, id);

        const node = this.createNode('a', id, label, (link) => {
            link.classList.add('active');
            if (action) action(label);
        }, '', href);

        document.documentElement.addEventListener('turbo:frame-load', (event) => {
            if (event.target === this.frameTarget) {
                node.querySelector('a').href = this.appendQuery(type, id);
            }
        });

        return node;
    }

    createNode (tagName, id, name, action, cssClass = '', href) {
        const li = document.createElement('li');
        const item = document.createElement(tagName);
        item.className = cssClass;
        item.append(name);

        if (tagName === 'button') {
            item.type = 'button';

            item.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                action(item);
            }, { capture: true, passive: false });
        } else if (tagName === 'a') {
            item.href = href;

            item.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                this.frameTarget.src = item.href;
                window.history.pushState(null, '', item.href);
                action(item);
            }, { capture: true, passive: false })
        }

        li.append(item);

        return li;
    }

    appendQuery(key, value) {
        const href = new URL(this.frameTarget.src || window.location.href);
        const params = href.searchParams;

        if (value) {
            params.set(key, value);
        } else {
            params.delete(key);
        }

        params.delete('page');

        href.search = params.toString();

        return href;
    }

    toggleClientGroup (el) {
        const active = el.classList.contains('active');

        el.parentNode.childNodes.forEach((node) => {
            node.classList.remove('active');
        });

        if (!active) {
            el.classList.add('active');
        }
    }
}
