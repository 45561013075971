import { Controller } from '@hotwired/stimulus';
import { tns as TinySlider } from 'tiny-slider/src/tiny-slider.module';
import 'tiny-slider/src/tiny-slider.scss';

export default class extends Controller {
    static targets = ['container', 'controls'];

    static values = {
        slideBy: String,
        items: Number,
        fixedWidth: Number,
        gutter: Number,
        autoWidth: {
            type: Boolean,
            default: false,
        },
        controls: {
            type: Boolean,
            default: true,
        },
        nav: {
            type: Boolean,
            default: false,
        },
        tabletFixedWidth: Number,
        tabletItems: Number,
        tabletGutter: Number,
        tabletControls: Boolean,
        tabletNav: Boolean,
        desktopFixedWidth: Number,
    }

    connect () {
        if (!this.hasContainerTarget) {
            return;
        }

        const responsive = { 768: {}, 1200: {} };

        if (this.hasTabletFixedWidthValue) {
            responsive['768'].fixedWidth = this.tabletFixedWidthValue;
        }

        if (this.hasTabletItemsValue) {
            responsive['768'].items = this.tabletItemsValue;
        }

        if (this.hasTabletGutterValue) {
            responsive['768'].gutter = this.tabletGutterValue;
        }

        if (this.hasTabletControlsValue) {
            responsive['768'].controls = this.tabletControlsValue;
        }

        if (this.hasTabletNavValue) {
            responsive['768'].nav = this.tabletNavValue;
        }

        if (this.hasDesktopFixedWidthValue) {
            responsive['1200'].fixedWidth = this.desktopFixedWidthValue;
        }

        this.slider = TinySlider({
            container: this.containerTarget,
            mode: 'carousel',
            items: this.itemsValue || null,
            slideBy: this.slideByValue || 1,
            autoWidth: this.autoWidthValue,
            autoHeight: false,
            fixedWidth: this.fixedWidthValue || false,
            gutter: this.gutterValue || 25,
            controls: this.controlsValue,
            controlsContainer: this.hasControlsTarget ? this.controlsTarget : false,
            nav: this.navValue,
            loop: false,
            responsive,
        });
    }

    disconnect () {
        if (this.slider) {
            this.slider.destroy();
        }
    }
}
