import { Controller } from '@hotwired/stimulus';
import Isotope from 'isotope-layout'

export default class extends Controller {
    static targets = ['grid', 'button'];
    static classes = ['active'];

    connect () {
        this.$grid = new Isotope(this.gridTarget, {
            layoutMode: 'fitRows'
        });
    }

    disconnect () {
        this.$grid.destroy();
        this.$grid = null;
    }

    filter (event) {
        event.preventDefault();

        this.$grid.arrange({ filter: event.params.filter });

        if (this.hasActiveClass && this.hasButtonTarget) {
            this.buttonTargets.forEach((el) => {
                el.classList.remove(this.activeClass);
            })

            event.target.classList.add(this.activeClass);
        }
    }

    reset () {
        this.$grid.arrange({ filter: '*' });

        if (this.hasActiveClass && this.hasButtonTarget) {
            this.buttonTargets.forEach((el) => {
                el.classList.remove(this.activeClass);
            })
        }
    }
}
