import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['class'];
    static classes = ['toggle'];

    connect () {
    }

    toggle () {
        if (this.hasClassTarget) {
            this.classTarget.classList.toggle(this.toggleClass)
        } else {
            document.body.classList.toggle(this.toggleClass)
        }
    }

    add () {
        if (this.hasClassTarget) {
            this.classTarget.classList.add(this.toggleClass)
        } else {
            document.body.classList.add(this.toggleClass)
        }
    }

    remove () {
        if (this.hasClassTarget) {
            this.classTarget.classList.remove(this.toggleClass)
        } else {
            document.body.classList.remove(this.toggleClass)
        }
    }
}
